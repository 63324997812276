<template>
    <div class="mt-2 mb-2">
        <CRow class="my-0 mx-0 mb-2 pr-0 d-flex">
            <CSelect 
                class="mr-2 mt-1 no-wrap my-0 form-group form-row filter-group px-0" 
                addLabelClasses="strong text-nowrap date-label-col mx-0 col-form-label col-sm-3 pr-0"
                :addInputClasses="{'text-warning': isChannelDirty, 'col ml-1 mr-1': true}"
                :options="channels"
                :value.sync="channel"
                label="Channel" 
            />
            <CSelect 
                class="mr-2 mt-1 no-wrap my-0 form-group form-row filter-group px-0" 
                addLabelClasses="strong text-nowrap date-label-col col-form-label col-sm-3 pl-1 pr-0"
                :addInputClasses="{'text-warning': isSkuDirty, 'col ml-1 mr-1': true}"
                :options="skus"
                :value.sync="sku"
                label="SKU" 
            >
            </CSelect>

            <CSelect 
                class="mr-2 mt-1 no-wrap my-0 form-group form-row filter-group px-0" 
                addLabelClasses="strong text-nowrap date-label-col col-form-label col-sm-3 pl-1 pr-0"
                :addInputClasses="{'text-warning': isRatingDirty, 'col ml-1 mr-1': true}"
                :options="ratings"
                :value.sync="rating"
                label="Rating" 
            >
            </CSelect>

            <CInput
                class="mr-2 mt-1 no-wrap my-0 filter-group px-0 my-0"
                addLabelClasses="strong text-nowrap date-label-col mx-0 pr-0"            
                :addInputClasses="{'text-warning': isDateFromDirty}"
                label="From"
                type="date"            
                horizontal
                :value="dateFrom"
                :is-valid="isDateFromValid"
                @update:value="onDateFromChanged"
            />
            <CInput
                class="mr-2 mt-1 no-wrap my-0 filter-group px-0 my-0"
                addLabelClasses="strong text-nowrap date-label-col pr-0"
                :addInputClasses="{'text-warning': isDateUntilDirty}"
                label="Until"
                type="date"            
                horizontal
                :value="dateUntil"
                :is-valid="isDateUntilValid"
                @update:value="onDateUntilChanged"
            />
            <CButton color="success" class="ml-0 pr-3 my-1 filter-btn" @click="onFindClick"><CIcon name="cil-search" class="mr-2 my-0 btn-icon" />Find</CButton>  
        </CRow>
        <CRow class="my-0 mx-0">        
            <div class="col form-inline mr-0 pr-0 mr-0 pl-0 filter-group">
                
                <!-- <CButton color="secondary" class="ml-2 pr-3 filter-btn"
                    @click="onResetClick"
                ><CIcon name="cil-x" class="mr-2 my-0 btn-icon" />Clear</CButton>   -->
            </div>
            <div class="col pl-0 ml-0">
                &nbsp;
            </div>
            <CCol class="col p-0">
                <div class="form-inline justify-content-sm-end">   
                    <strong class="mr-3" v-if="total">Selected Feedbacks: {{total}}</strong>
                <CSelect 
                    :options="[10,25,50,100]" 
                    add-label-classes="pr-2"
                    :value="itemsPerPage"
                    label="Items per page"
                    @update:value="onPageSizeChange"
                />
                </div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import { VIEW_FEEDBACK, mapPermissions } from '@/permissions'
import { mapGetters, mapActions } from 'vuex';
import { LOAD_SETTINGS } from '@/store/modules/reports'
export default {
    data() {
        return {
            dateFrom: null,
            dateUntil: null,
            channel: null,        
            sku: null,
            rating: null,
            itemsPerPage: 10,
            isChannelDirty: false,
            isSkuDirty: false,
            isDateFromDirty: false,
            isDateUntilDirty: false,    
            isRatingDirty: false        
        }
    },
    props: {
        total: Number,
    },
    computed: {
        ...mapPermissions({
            canView: VIEW_FEEDBACK
        }),
        ...mapGetters({
            channelOptions: 'reports/channelOptions',
            skuOptions: 'reports/skuOptions',
            loading: 'reports/loading'
        }),
        channels() {            
            return (this.channelOptions && this.channelOptions.length == 1)
                  ? this.channelOptions: [{label: 'Any', value: null}, ...this.channelOptions];
        },
        ratings() {
            const stars = [...Array(5).keys()].reverse()
                .map(o => { 
                    return { 
                        value: o + 1, 
                        label: "🟊".repeat(o+1) + "☆".repeat(4-o) + ` (${o+1})`}
                });
            return [{label: 'Any', value: null}, ...stars];
        },
        skus() {
            return [{label: 'Any', value: null}, ...this.skuOptions];
        },
        isDateRangeValid() {
            return (this.dateFrom && this.dateUntil && this.dateFrom > this.dateUntil) ? false : null;
        },        
    },
    methods: {
        ...mapActions({
            loadSettings: 'reports/' + LOAD_SETTINGS
        }),
        onPageSizeChange(val) {
            this.itemsPerPage = val;
            this.$emit("pageSizeChange", val);
        },
        onDateFromChanged(val) {
            this.dateFrom = val ? val : null;
            this.setIsDirty();
        },
        onDateUntilChanged(val) {
            this.dateUntil = val ? val : null;
            this.setIsDirty();
        },
        onFindClick() {
            if (this.isDateRangeValid !== false) {
                const filter = this.createFilter();                
                this.$emit("filterChange", {...filter, itemsPerPage: this.itemsPerPage});
                this.activeFilter = {...filter};
                this.setIsDirty();
            }
        },
        // Validation
        isDateFromValid() {
            return this.isDateRangeValid;
        },
        isDateUntilValid() {
            return this.isDateRangeValid;
        },
        // reset dirty
        setIsDirty() {
            const f = this.activeFilter;
            this.isDateFromDirty = this.dateFrom != f.dateFrom;
            this.isDateUntilDirty = this.dateUntil != f.dateUntil;  
            this.isChannelDirty = this.channel != f.channel;
            this.isSkuDirty = this.sku != f.sku;
            this.isRatingDirty = this.rating != f.rating;
        },
        createFilter() {
            let endDate = this.dateUntil;
            if (this.dateUntil) {
                endDate = new Date(this.dateUntil + " 00:00");
                endDate.setDate(endDate.getDate() + 1);
            }
            return {
                dateFrom:  this.dateFrom ? new Date(this.dateFrom + " 00:00") : null,
                dateUntil: endDate,
                channel:   this.channel,
                sku:       this.sku,
                rating:    this.rating
            };
        }
    },
    watch: {
        channel() {
            this.setIsDirty();
        },
        sku() {
            this.setIsDirty();
        },
        rating() {
            this.setIsDirty()
        }
    },
    created() {        
        this.loadSettings();
        this.activeFilter = {};
    }
}
</script>
<style scoped>
.filter-group {
    max-width: 240px;
    width: 240px;
}
.filter-btn {
    width: 100px;
}
.btn-icon {
    transform: translateY(-2px);
}
</style>
<style>
.date-label-col {
  max-width:70px; 
  min-width:40px; 
  width:40px;
  text-align: center;
}
.move-y-shipped-input {
    transform: translateY(-2px);
}
</style>