<template>
    <div style="display:inline-block">
        <img v-for="star in stars" :src="star.src" :key="star.n" class="star" />
    </div>
</template>
<script>
export default {
    props: {
        rating: Number,
    },
    computed: {
        stars() {
            return [...Array(5).keys()].map(n => {
                return { 
                    n: n,
                    src: (n < this.rating ? require('@/assets/images/star-fill.svg') : require('@/assets/images/star-void.svg')) 
                }
            });
        }
    },
}
</script>
<style scoped>
.star {
    width: 15px;
    transform: translateY(-2px);
}
</style>
