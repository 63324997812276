var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',{staticClass:"pt-2 pb-0 d-flex flex-row"},[_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cil-star","size":"lg"}}),_c('h5',{},[_vm._v("Feedbacks")])],1),_c('CCardBody',{staticClass:"pt-0"},[_c('FeedbackFilter',{attrs:{"total":_vm.totalItems},on:{"pageSizeChange":_vm.onPageSizeChange,"filterChange":_vm.onFilterChange}}),_c('CDataTable',{staticClass:"feedbacks-table",attrs:{"loading":_vm.loading,"fields":_vm.fields,"items":_vm.items,"sorter":{resetable: false},"sorterValue":_vm.sorterValue},on:{"update:sorter-value":_vm.onSortChanged},scopedSlots:_vm._u([{key:"dateUtc",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap px-0",class:{'preview': item.isPreview}},[_vm._v(" "+_vm._s(_vm.formatDate(item.dateUtc))),_c('br'),(item.device ==='desktop')?_c('CIcon',{attrs:{"name":"cil-screen-desktop"}}):_vm._e(),(item.device ==='mobile')?_c('CIcon',{attrs:{"name":"cil-mobile"}}):_vm._e()],1)]}},{key:"sku",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pl-3 pr-0",class:{'preview': item.isPreview}},[_c('span',{staticClass:"text-nowrap font-weight-500"},[_vm._v(_vm._s(item.sku))]),_c('br'),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.productName))])])]}},{key:"orderNo",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap pr-0 pl-3",class:{'preview': item.isPreview}},[_c('span',{staticClass:"font-weight-500"},[_vm._v(_vm._s(item.orderNo))]),_c('br'),_c('span',{staticClass:"text-nowrap text-info"},[_vm._v(_vm._s(_vm.getChannelName(item.channel)))])])]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pl-3 pr-0",class:{'preview': item.isPreview}},[_c('span',{staticClass:"text-nowrap font-weight-500"},[_vm._v(_vm._s(item.name))]),(item.email)?_c('br'):_vm._e(),_c('span',{staticClass:"text-nowrap"},[(item.email)?_c('a',{staticClass:"text-info",attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))]):_vm._e(),(item.emailOptIn)?_c('CIcon',{staticClass:" mt-0 ml-1 text-success",staticStyle:{"transform":"translateY(-2px)"},attrs:{"name":"cil-check-circle","title":"Opt In"}}):_vm._e()],1)])]}},{key:"rating",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap pl-3 pr-0",class:{'preview': item.isPreview}},[_c('Rating',{staticClass:"mr-1",staticStyle:{"transform":"translateY(-1px)"},attrs:{"rating":item.rating}}),_c('div',{staticStyle:{"display":"inline-block","transform":"translateY(-3px)"}},[(item.isReview && _vm.canViewInternal)?_c('CIcon',{staticClass:"mt-0 ml-1 text-info",attrs:{"name":"cil-touch-app","title":"Review site visit"}}):_vm._e(),(item.risk && _vm.canViewInternal)?_c('CIcon',{staticClass:"mt-0 ml-1 text-danger",attrs:{"title":item.risk,"name":"cil-ban"}}):_vm._e(),(item.reviewCap && _vm.canViewInternal)?_c('CIcon',{staticClass:" mt-0 ml-2 text-primary",attrs:{"title":item.risk,"name":"cil-ban"}}):_vm._e(),(item.isConversion && _vm.canViewConversion)?_c('CIcon',{staticClass:"mt-0 ml-1 text-success",attrs:{"name":"cil-check","title":"Verified review"}}):_vm._e()],1),_c('br'),_c('span',{staticClass:"font-xs font-italic text-danger"},[_vm._v(_vm._s(item.risk))])],1)]}},{key:"text",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pl-3 pr-0",class:{'preview': item.isPreview}},[_c('p',{staticClass:"text-muted review-text font-xs mb-0 pb-0"},[_vm._v(" "+_vm._s(item.text)+" "),(_vm.canEditConversion)?_c('span',[_vm._v(" "),_c('CLink',{staticClass:"ml-1",attrs:{"title":"Mark as conversion","disabled":item.isConversion},on:{"click":function($event){return _vm.markConversion(item, true)}}},[_c('CIcon',{class:{ 'text-success': !item.isConversion, 'text-secondary': item.isConversion },attrs:{"name":"cil-check-circle","size":"sm"}})],1),_c('CLink',{staticClass:"ml-1",attrs:{"title":"Clear conversion","disabled":!item.isConversion},on:{"click":function($event){return _vm.markConversion(item, false)}}},[_c('CIcon',{class:{ 'text-danger': item.isConversion, 'text-secondary': !item.isConversion },attrs:{"name":"cil-x","size":"sm"}})],1)],1):_vm._e()])])]}}])}),_c('CPagination',{attrs:{"pages":_vm.pages,"active-page":_vm.activePage},on:{"update:activePage":function($event){_vm.activePage=$event},"update:active-page":function($event){_vm.activePage=$event}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }