<template>
    <CRow>
      <CCol sm="12">           
        <CCard>
            <CCardHeader class="pt-2 pb-0 d-flex flex-row">    
                <CIcon name="cil-star" size="lg" class="mr-2" /><h5 class="">Feedbacks</h5> 
            </CCardHeader>
            <CCardBody class="pt-0">
                <FeedbackFilter 
                  :total="totalItems"
                  @pageSizeChange="onPageSizeChange"
                  @filterChange="onFilterChange"
                />
                <CDataTable     
                  class="feedbacks-table"
                  :loading="loading"
                  :fields="fields"
                  :items="items"
                  :sorter='{resetable: false}'
                  :sorterValue='sorterValue'
                  @update:sorter-value="onSortChanged"
                >   
                  <template #dateUtc="{item}">
                    <td class="text-nowrap px-0" :class="{'preview': item.isPreview}">
                      {{formatDate(item.dateUtc)}}<br />
                      <CIcon v-if="item.device ==='desktop'" name="cil-screen-desktop" />
                      <CIcon v-if="item.device ==='mobile'" name="cil-mobile" />
                    </td>
                  </template>
                  <template #sku="{item}">
                    <td class="pl-3 pr-0" :class="{'preview': item.isPreview}">
                      <span class="text-nowrap font-weight-500">{{ item.sku }}</span><br />
                      <!-- <span class="text-nowrap text-info">{{ getChannelName(item.channel) }}</span> -->
                      <span class="text-nowrap">{{ item.productName }}</span>
                    </td>
                  </template>
                  <template #orderNo="{item}">
                    <td class="text-nowrap pr-0 pl-3" :class="{'preview': item.isPreview}">
                      <span class="font-weight-500">{{ item.orderNo }}</span><br />
                      <!-- <span class="text-nowrap text-dark">{{formatDate(item.orderDate)}}</span> -->
                      <span class="text-nowrap text-info">{{ getChannelName(item.channel) }}</span>
                    </td>
                  </template>
                  <template #email="{item}">
                    <td class="pl-3 pr-0" :class="{'preview': item.isPreview}">
                      <span class="text-nowrap font-weight-500">{{ item.name }}</span>
                      <br v-if="item.email" />
                      <span class="text-nowrap">
                        <a v-if="item.email" :href="`mailto:${item.email}`" class="text-info"><!--<CIcon size="sm" name="cil-envelope-closed" class="mb-2 mr-1" style="transform: translateY(1px)"/>-->{{item.email}}</a>
                        <CIcon v-if="item.emailOptIn" name="cil-check-circle" class=" mt-0 ml-1 text-success" title="Opt In" style="transform: translateY(-2px)" />
                      </span>
                    </td>
                  </template>
                  <template #rating="{item}">
                    <td class="text-nowrap pl-3 pr-0" :class="{'preview': item.isPreview}">
                      <Rating :rating="item.rating" style="transform:translateY(-1px);" class="mr-1" />   
                      <div style="display: inline-block; transform: translateY(-3px)">              
                        <CIcon v-if="item.isReview && canViewInternal" name="cil-touch-app" class="mt-0 ml-1 text-info" title="Review site visit" /> 
                        <CIcon v-if="item.risk && canViewInternal" :title="item.risk" name="cil-ban" class="mt-0 ml-1 text-danger" /> 
                        <CIcon v-if="item.reviewCap && canViewInternal" :title="item.risk" name="cil-ban" class=" mt-0 ml-2 text-primary" />
                        <CIcon v-if="item.isConversion && canViewConversion" name="cil-check" class="mt-0 ml-1 text-success" title="Verified review" />                      
                      </div>
                      <br><span class="font-xs font-italic text-danger">{{ item.risk }}</span>                      
                    </td>
                  </template>
                  <template #text="{item}">
                    <td class="pl-3 pr-0" :class="{'preview': item.isPreview}">
                      <p class="text-muted review-text font-xs mb-0 pb-0">
                        {{ item.text }}
                        <span v-if="canEditConversion">
                        &nbsp;
                        <CLink @click="markConversion(item, true)" title="Mark as conversion" class="ml-1" :disabled="item.isConversion"><CIcon name="cil-check-circle" size="sm" :class="{ 'text-success': !item.isConversion, 'text-secondary': item.isConversion }" /></CLink>
                        <CLink @click="markConversion(item, false)" title="Clear conversion" class="ml-1" :disabled="!item.isConversion"><CIcon name="cil-x" size="sm" :class="{ 'text-danger': item.isConversion, 'text-secondary': !item.isConversion }" /></CLink>                        
                       </span>
                      </p>                      
                    </td>
                  </template>
                </CDataTable>
                <CPagination
                  :pages="pages"
                  :active-page.sync="activePage"            
                />
            </CCardBody>
        </CCard>
    </CCol>
    </CRow>
</template>

<script>
import FeedbackFilter from '@/components/FeedbackFilter';
import Rating from '@/components/Rating';
import { feedbacks as feedbacksApi } from '@/api/';
import { mapGetters } from 'vuex';
import { mapPermissions, VIEW_INTERNAL,VIEW_CONVERSION,EDIT_CONVERSION  } from '@/permissions';
import { formatDate } from '@/utils';

export default {
  components: {
    FeedbackFilter,
    Rating,
  },  
  data() {
    return { 
      loading: false,
      items: null,      
      itemsPerPage: 10,
      totalItems: 0,
      activePage: 1,
      pages: 1,
      sort: 'descending',
      filter: null
    }
  },
  computed: {
    ...mapGetters({
      merchantId: 'merchantId',
      channels: 'reports/channelOptions'
    }),
    ...mapPermissions({
          canViewInternal: VIEW_INTERNAL,
          canViewConversion: VIEW_CONVERSION,
          canEditConversion: EDIT_CONVERSION
      }),
    sorterValue() {
      const sorter = { 
        column: 'dateUtc', 
        asc: this.sort == 'ascending', 
        desc: this.sort == 'descending'
      }
      return sorter;
    },
    fields() {
      return [
        { label: 'Date', key: 'dateUtc', sorter: true, _classes: ['px-0','width-auto'], _style: ''},
        { label: 'SKU / Product', key: 'sku', sorter: false, /*_classes: ['pl-3', 'pr-0', 'text-nowrap'], */  _style: 'width: auto' },
        // { label: 'Channel', key: 'channel', sorter: false, _classes: ['pl-3', 'text-nowrap'], _style: 'width: auto;' },
        { label: 'Order No. / Channel', key: 'orderNo', sorter: false, _classes: ['pl-3', 'text-nowrap'], _style: 'width: auto' },        
        // { label: 'Order Date', key: 'orderDate', sorter: false, _classes: 'px-0', _style: 'width: auto' },  
        { label: 'Name / E-Mail', key: 'email', sorter: false, _classes: ['pl-3', 'text-nowrap'] },
        { label: 'Rating', key: 'rating', sorter: false, _style: 'width: auto' },        
        { label: 'Text', key: 'text', sorter: false, _style: 'width: 100%' },        
        // { label: 'Reward', key: 'bonusSKU', sorter: false },
        // { label: 'Name / Address',  key: 'address', sorter: false },
        // { label: 'E-Mail / Phone', key: 'email', sorter: false },
        // { label: 'Shipped', key:'shipped', sorter: false, _style:"max-width: 110px; min-width: 110px" },
        // { label: 'Comment', key:'comment', sorter: false },
      ];
    }
  },
  methods: {
    onPageSizeChange(val) {
      this.itemsPerPage = val;
      this.activePage = 1;
      this.loadFeedbacks();
    },
    onSortChanged(val) {
      this.sort = val.asc ? "ascending" : "descending";
      this.loadFeedbacks();
    },
    onFilterChange(val) {
      this.filter = val;
      this.activePage = 1;
      this.loadFeedbacks();
    }, 
    formatDate: (dateStr) => formatDate(dateStr),
    getChannelName(ch) {
      return this.channels ? this.channels.find(o => o.value == ch)?.label : null;
    },
    getFilter() {
      let dateFrom = this.filter?.dateFrom;
      let dateUntil = this.filter?.dateUntil;     

      const filter = { 
        ...this.filter,
        dateFrom: dateFrom? dateFrom.toISOString() : null,
        dateUntil: dateUntil ? dateUntil.toISOString() : null,
        itemsPerPage: this.itemsPerPage, 
        pageIndex: this.activePage - 1,
        dateSort: this.sort
      };

      return filter;
    },
    async loadFeedbacks() {
      this.loading = true;      
      const filter = this.getFilter();

      // eslint-disable-next-line
      let response = null;      
      try {                 
        response = await feedbacksApi.find({merchantId: this.merchantId, filter });
      }
      catch(e) {        
        this.loading = false;
        return;
      }

      this.loading = false;      
      this.items = response.data.feedbacks;
      const total = response.data.total;
      this.totalItems = total;

      this.pages = Math.ceil(total / this.itemsPerPage);
    },
    async markConversion(item, conversion) {      
      try {                 
        await feedbacksApi.conversion({merchantId: this.merchantId,feedbackIds: [item.id], conversion });
        item.isConversion = conversion;
      }
      catch(e) {  
        return;
      }
    }
  },
  watch: {
    activePage() {
      this.loadFeedbacks();
    }
  },
  created() {
    this.loadFeedbacks();
  }
}
</script>

<style>
.feedbacks-table .table-responsive {
  min-height: 60px !important;
}
/* .feedbacks-table .table-responsive .table {
  margin-bottom: 8px;
} */
.feedbacks-table th {
  padding-right: 0px !important;
}
.width-auto {
  width: auto;
}
.review-text {
  text-overflow: ellipsis;
  overflow: hidden;
}
.preview {
  opacity: 0.6;
}
</style>